<template>
  <div :class="`index ${$utils.isMobile().mobile ? 'mobile' : ''}`" ref="index">
    <pop-login
      :show="popLoginShow"
      :projectcode="project_code"
      @close="popLoginClose"
      @loginsuccess="loginSuccess"
    ></pop-login>
    <pop-password
      :show="popPasswordShow"
      @close="popPasswordClose"
      @success="popPasswordVerify"
    ></pop-password>
    <pop-tag
      :show="popTagShow"
      @close="popTagClose"
      @submit="popTagSubmit"
    ></pop-tag>
    <div class="page-one" ref="pageOne">
      <pop-top
        @open="popLoginOpen"
        @logout="popLogout"
        :weappPathFinished="weappPathFinished"
        :weappPath="weappPath"
      ></pop-top>
      <div class="page-info">
        <div class="logo"></div>
        <div class="title">
          <span>欢迎来到</span>
          <span>博士科技元宇宙展厅</span>
        </div>
        <div class="page-action">
          <div
            class="action-item active cursor-pointer"
            v-if="$utils.checkWxOrMini(0) && weappPathFinished"
            @click.stop.prevent="goGame"
          >
            <span>立即体验</span>
          </div>
          <div
            class="action-item active cursor-pointer"
            v-if="$utils.checkWxOrMini(1) && weappPathFinished"
          >
            <WxOpenLaunchWeapp :path="weappPath">
              <div :style="weappStyle.div">
                <span :style="weappStyle.span">立即体验</span>
              </div>
            </WxOpenLaunchWeapp>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { routesModuleData, routesConvert } from "@/router/index";
import PopPassword from "@/components/PopPassword.vue";
import PopTag from "@/components/PopTag.vue";
import WxOpenLaunchWeapp from "@/components/WxOpenLaunchWeapp.vue";
import { wxShare } from "@/assets/js/wechatAuth.js";
import PopLogin from "@/components/PopLogin.vue";
import PopTop from "@/components/PopTop.vue";

export default {
  components: { PopLogin, PopTop, WxOpenLaunchWeapp, PopPassword, PopTag },
  data() {
    return {
      href: location.href,
      popLoginShow: false,
      popPasswordShow: false,
      project_code: "",
      project_detail: {},
      activityList: [],
      activityListCurrentIndex: 0,
      activityListOrigin: [],
      projectVerifyPassword: false,
      weappStyle: {
        div: {
          width: "100%",
          height: "100%",
          borderRadius: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          lineHeight: "0",
          transition: "all 0.3s ease-in-out",
          boxSizing: "border-box",
          position: "absolute",
          left: "0",
          top: "0",
        },
        span: {
          fontSize: "6px",
          fontFamily: "Source Han Sans CN-Normal, Source Han Sans CN",
          fontWeight: "400",
          color: "#ffffff",
        },
      },
      weappPathFinished: false,
      weappLoginFinished: false,
      qrcode_scene: "",
      tagId: "",
      popTagShow: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    weappPath() {
      return `subpackage_login/game/game?id=${sessionStorage.getItem(
        "activity_id"
      )}${process.env.NODE_ENV == "development" ? "_1" : ""}&ext=bskj`;
    },
  },
  created() {
    this.qrcode_scene = Date.now();
    this.getInfo();
    this.getUserInfo();
    document.title = process.env.VUE_APP_DEFAULT_TITLE;
    window.addEventListener("resize", this.getInfo);
    wxShare({});
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getInfo);
  },
  mounted() {},
  methods: {
    async getUserInfo(callback) {
      if (this.$storage.getToken()) {
        const requestParam = {};
        if (this.tagId) {
          requestParam["tag_id"] = this.tagId;
        }
        const userInfoResult = await this.$http.get(
          "/user/userinfo",
          requestParam
        );
        if (userInfoResult.code == 200) {
          this.$store.dispatch("saveUserInfo", userInfoResult.data.user_info);
          this.$store.dispatch("saveConfig", userInfoResult.data.config);
          if (userInfoResult.data.user_info["is_black"] == 1) {
            // 封禁用户，禁止进入活动
            this.$toast.fail("账号被封禁，无法登入");
          }
          console.log(userInfoResult.data.user_info, "userInfo");
        } else {
          this.$store.dispatch("clearUserInfo");
          this.$store.dispatch("clearConfig", "");
        }
        this.getDetailInfo(callback);
      } else {
        this.getDetailInfo(callback);
      }
    },
    async getDetailInfo(callback) {
      this.$http
        .get("/activity/detail", {
          is_boshi: 1,
        })
        .then((res) => {
          if (res.code == 200 && res.data) {
            this.$toast.clear();
            this.project_code = res.data.project_code;
            const link =
              location.href +
              ((location.href.indexOf("?") > 0 ? "&" : "?") + "share=1");
            this.project_detail["href"] = link;
            this.project_detail["name"] = res.data.name;
            this.project_detail["no"] = res.data.no;
            this.project_detail["remark"] = res.data.remark;
            this.project_detail["start_time"] = res.data.start_time;
            this.project_detail["end_time"] = res.data.end_time;
            this.project_detail["status_remark"] = res.data.status_remark;
            this.project_detail["status"] = res.data.status;
            this.project_detail["title"] = res.data.title;
            this.project_detail["cover"] = res.data.cover;
            this.project_detail["need_password"] = res.data.need_password;
            this.project_detail["access_rule"] = res.data.access_rule;
            this.project_detail["id"] = res.data.id;
            this.$store.dispatch("saveDetail", this.project_detail);
            sessionStorage.setItem("appLink", res.data.activity_unity_link);
            sessionStorage.setItem("appKey", res.data.activity_unity_app_key);
            if (!this.project_detail["need_password"]) {
              this.projectVerifyPassword = true;
            }
            sessionStorage.setItem("project_name", this.project_detail["name"]);
            wxShare({
              title: this.project_detail.title,
              link: link,
            });
            if (
              this.userInfo &&
              sessionStorage.getItem("isWxMini") == 1 &&
              this.$utils.getUrlParam("share") != 1 &&
              sessionStorage.getItem("backDetail") != 1
            ) {
              this.goGame();
              sessionStorage.setItem("backDetail", 1);
            }
            callback && callback();
          } else {
            this.$toast.fail("加载失败：" + res.msg);
          }
          this.weappPathFinished = true;
        });
    },
    popPasswordVerify() {
      this.projectVerifyPassword = true;
      this.popPasswordClose();
      this.goGame();
    },
    popPasswordOpen() {
      this.popPasswordShow = true;
    },
    popPasswordClose() {
      this.popPasswordShow = false;
    },
    goGame() {
      if (!this.userInfo) {
        this.popLoginOpen();
        return;
      }
      if (this.userInfo["is_black"] == 1) {
        // 封禁用户，禁止进入活动
        this.$toast.fail("账号被封禁，无法登入");
        return;
      }
      if (!this.project_detail["access_rule"]) {
        // 该活动仅指定用户可访问
        this.$toast.fail("该活动仅指定用户可访问");
        return;
      }
      if (this.project_detail["need_password"] && !this.projectVerifyPassword) {
        this.popPasswordOpen();
        return;
      }
      if (this.project_detail["status"] != 2) {
        this.$toast.fail(`活动${this.project_detail["status_remark"]}`);
        return;
      }
      if (!this.tagId) {
        this.popTagOpen();
        return;
      }
      this.$toast.clear();
      this.$router.push({
        name: routesConvert(routesModuleData.game, true).name,
        query: {
          id: this.project_detail["id"],
        },
      });
    },
    popTagOpen() {
      this.popTagShow = true;
    },
    popTagClose() {
      this.popTagShow = false;
    },
    async popTagSubmit(item) {
      console.log(item, "item");
      this.$toast.loading({
        message: "正在加载中...",
        duration: 3000,
        forbidClick: true,
      });
      this.tagId = item.id;
      this.popTagClose();
      this.getUserInfo(this.goGame);
    },
    popLogout() {
      this.weappLoginFinished = false;
      this.qrcode_scene = Date.now();
      this.internalRequest();
    },
    internalRequest() {
      // 场景：微信h5内首页有唤醒小程序扫码入口，需持续轮询检测登录。
      if (this.userInfo) {
        // 已登录
        return;
      }
      if (!this.$utils.checkWxOrMini(1)) {
        // 非微信h5端内
        return;
      }
      if (this.weappLoginFinished) {
        // 已扫码登录完成
        return;
      }
      const _this = this;
      _this.$http
        .post("/user/wxAuth", {
          qrcode_scence: _this.qrcode_scene,
        })
        .then((res) => {
          if (res.code == 200 && res.data && res.data.user_info) {
            _this.$storage.setToken(res.data.user_info.token);
            _this.$store.dispatch("saveUserInfo", res.data.user_info);
            _this.$store.dispatch("saveConfig", res.data.config);
            _this.$toast.success("登录成功");
            _this.weappLoginFinished = true;
            _this.qrcode_scene = Date.now();
          } else {
            setTimeout(() => {
              _this.internalRequest();
            }, 2000);
          }
        });
    },
    getInfo() {
      this.$http
        .get("/activity/activity-center", {
          page: 1,
          per_page: 999,
        })
        .then((res) => {
          if (res.code == 200 && res.data) {
            this.project_code = res.data.project_code;
            this.weappPathFinished = true;
            this.internalRequest();
          }
        });
    },
    logout() {
      this.$storage.clearToken();
      this.$store.dispatch("clearUserInfo", "");
      this.$store.dispatch("clearConfig", "");
      this.$toast.success("已退出登录");
    },
    popLoginClose() {
      this.popLoginShow = false;
    },
    popLoginOpen() {
      this.popLoginShow = true;
    },
    onCopy() {
      this.$toast.success("复制成功");
    },
    onError() {
      this.$toast.fail("复制失败");
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes shrine {
  0% {
    position: absolute;
    left: -100%;
    top: 0;
    width: 30%;
    height: 100%;
    content: "";
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: skewX(-45deg);
    transition: 0.5s ease-in-out;
  }
  100% {
    left: 300%;
    transition: 0.5s ease-in-out;
  }
}
.shrining {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  user-select: none;
  z-index: 99;
  animation: shrine 4s infinite linear;
}
.index {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  .page-one {
    width: 100%;
    height: 100%;
    background: url(#{$PublicEnv}/bskj/pc_bg.png);
    background-size: 100% 100%;
    background-position: center;
    transition: 1s ease-in-out;
    animation: backgroundMove 8s infinite linear;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .page-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .logo {
        width: 1067.2px;
        height: 676.8px;
        background: url(#{$PublicEnv}/bskj/bskj_logo.png);
        background-size: 100% 100%;
      }
      .title {
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          font-size: 72px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .page-action {
        margin-top: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        .action-item {
          width: 360px;
          height: 120px;
          border-radius: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          position: relative;
          span {
            width: 100%;
            height: 100%;
            line-height: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 48px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
          &.active {
            background: linear-gradient(90deg, #00f7f7 0%, #f76000 100%);
          }
        }
      }
    }
  }
  &.mobile {
    .page-one {
      animation: none;
    }
  }
}
@media (orientation: portrait) {
  .index {
    .page-one {
      background: url(#{$PublicEnv}/bskj/pc_bg.png);
      background-size: auto 100%;
      background-position-x: 52%;
      animation: none;
      .page-info {
        transform: scale(2.5);
      }
    }
  }
}
</style>
